import React from 'react'
import SeccionMapa from '../components/SeccionMapa'
import Layout from '../components/Layout'

const contacto = () => {
    return (
        <Layout>
        
            <SeccionMapa titulo="Contacto"/>
            
        </Layout>
    )
}


export default contacto
